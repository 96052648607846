import React, { useEffect, useRef } from "react";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import "../assets/scss/terms-of-use/index.scss";
import InfoSection from "../components/instruction-product-report/InfoSection";
import Offer from "../components/instruction-product-report/Offer";
import "../components/instruction-product-report/InfoSection/index.scss";

const OaInstructionProductReport = () => {
  const guideRef = useRef(null);

  const transitionToRef = (refParameter) => {
    !!refParameter &&
      !!refParameter.current &&
      refParameter.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.hash === "#guide") {
        transitionToRef(guideRef);
      }
    }
  }, []);
  return (
    <Layout bgHeader="#fff">
      <Seo
        title="Here you can find a short user's guide for our product | AMZScout"
        description="Here you can find a short user's guide for our product | AMZScout"
        page="oa-instruction-product-report"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <Offer />
      <InfoSection guideRef={guideRef} isOaVersion />
    </Layout>
  );
};

export default OaInstructionProductReport;
